import { allWallImages } from '@frontend/images/DynamicImages';
import { SelectionObject, SelectionObjectCategories } from '@core/src/types/SelectionObject';
// Constraint rules are global or geberit systems, if this changes, a different implementation has to be made
export const SELECTION_OBJECTS_WALLS: SelectionObject[] = [
  /* WALL MOUNTED PRE WALLS */
  {
    objectType: 'wxooo',
    image: allWallImages.wxooo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
        maxDepth: 42,
      },
    ],
  },
  {
    objectType: 'wooox',
    image: allWallImages.wooox,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'right',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
        maxDepth: 42,
      },
    ],
  },
  {
    objectType: 'wxoox',
    image: allWallImages.wxoox,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: true,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
        maxDepth: 42,
      },
    ],
  },
  {
    objectType: 'woooo',
    image: allWallImages.woooo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'none',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
        maxDepth: 42,
      },
    ],
  },
  {
    objectType: 'wxxxo',
    image: allWallImages.wxxxo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxxx',
    image: allWallImages.woxxx,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'right',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxxxx',
    image: allWallImages.wxxxx,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: true,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxxo',
    image: allWallImages.woxxo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'none',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxxoo',
    image: allWallImages.wxxoo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'right',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxox',
    image: allWallImages.woxox,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'right',
      fixedPosition: 'right',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxxox',
    image: allWallImages.wxxox,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: true,
      partialPanel: 'right',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxoo',
    image: allWallImages.woxoo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'right',
      fixedPosition: 'none',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxoxo',
    image: allWallImages.wxoxo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'left',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wooxx',
    image: allWallImages.wooxx,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'left',
      fixedPosition: 'right',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxoxx',
    image: allWallImages.wxoxx,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: true,
      partialPanel: 'left',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wooxo',
    image: allWallImages.wooxo,
    type: 'walls',
    category: 'normalWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'left',
      fixedPosition: 'none',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 10,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  /* DIVIDER WALLS */
  {
    objectType: 'wxooos',
    image: allWallImages.wxooos,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'left',
      systemRequired: 'Gis',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 220,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
    ],
  },
  {
    objectType: 'woooxs',
    image: allWallImages.woooxs,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'right',
      systemRequired: 'Gis',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 220,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
    ],
  },
  {
    objectType: 'wxooxs',
    image: allWallImages.wxooxs,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: true,
      partialPanel: 'none',
      fixedPosition: 'left',
      systemRequired: 'Gis',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
    ],
  },
  {
    objectType: 'woooos',
    image: allWallImages.woooos,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: false,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'none',
      systemRequired: 'Gis',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 220,
        maxWidth: 500,
        minDepth: 25,
        maxDepth: 44,
      },
    ],
  },
  {
    objectType: 'wxxxos',
    image: allWallImages.wxxxos,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxxxs',
    image: allWallImages.woxxxs,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'right',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'wxxxxs',
    image: allWallImages.wxxxxs,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: true,
      partialPanel: 'none',
      fixedPosition: 'left',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        minDepth: 13,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },
  {
    objectType: 'woxxos',
    image: allWallImages.woxxos,
    type: 'walls',
    category: 'dividerWall',
    wallSituation: {
      sameHeightWallPanel: true,
      sameWidthWallPanel: false,
      partialPanel: 'none',
      fixedPosition: 'none',
    },
    wallConstraints: [
      {
        system: 'Gis',
        maxHeight: 600,
        maxWidth: 500,
        minDepth: 13,
        maxDepth: 45,
      },
      {
        system: 'Duofix',
        maxHeight: 400,
        minDepth: 13.5,
      },
    ],
  },

];

export const SELECTION_CATEGORIES_LIST: SelectionObjectCategories[] = [
  {
    type: 'walls',
    title: 'EditorWallsTitle',
    categories: [
      {
        id: 'normalWall',
        title: 'EditorPreWallCategory',
      },
      {
        id: 'dividerWall',
        title: 'EditorRoomDividerCategory',
      },
    ],
  },
  {
    type: 'products',
    title: 'EditorProductsTitle',
    cssClasses: ' products-categories ',
    categories: [
      {
        id: 'wcs',
        title: 'EditorWcsProductCategory',
      },
      {
        id: 'washbasins',
        title: 'EditorWashbasinsProductCategory',
      },
      {
        id: 'elements',
        title: 'EditorElementsProductCategory',
      },
      {
        id: 'showers',
        title: 'EditorShowersProductCategory',
      },
      {
        id: 'bathtubs',
        title: 'EditorBathtubsProductCategory',
      },
      {
        id: 'urinals',
        title: 'EditorUrinalsProductCategory',
      },
      {
        id: 'misc',
        title: 'EditorMiscProductCategory',
      },
    ],
  },
];
