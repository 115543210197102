import { ApiRequestConfigInfoMap } from '@core/src/types/api-requests/ApiRequestConfigInfo';

export const BACKEND_API_ENDPOINTS: ApiRequestConfigInfoMap = {
  gigyaScript: {
    endpoint: '/api/gigya/{locale}/{gigyaEnvironment}',
    method: 'GET',
    sendToken: false,
  },
  login: {
    endpoint: '/api/gigya/login',
    method: 'POST',
    sendToken: false,
  },
  products: {
    endpoint: '/api/products/{marketId}',
    method: 'GET',
    sendToken: false,
  },
  getNewClientId: {
    endpoint: '/api/proplanner/client',
    method: 'POST',
    sendToken: false,
  },
  getMarketValues: {
    endpoint: '/api/proplanner/market/{marketId}/values',
    method: 'GET',
    sendToken: false,
  },
  getUserProjects: {
    endpoint: '/api/projects/user',
    method: 'GET',
    sendToken: true,
  },
  getProject: {
    endpoint: '/api/projects/user/{projectId}',
    method: 'GET',
    sendToken: true,
  },
  projectCreate: {
    endpoint: '/api/projects',
    method: 'POST',
    sendToken: true,
  },
  projectDelete: {
    endpoint: '/api/projects/{projectId}',
    method: 'DELETE',
    sendToken: true,
  },
  projectReportGuest: {
    endpoint: '/api/projects/report',
    method: 'POST',
    sendToken: false,
  },
  projectUpdate: {
    endpoint: '/api/projects/{projectId}',
    method: 'PUT',
    sendToken: true,
  },
  projectReport: {
    endpoint: '/api/projects/{projectId}/report',
    method: 'GET',
    sendToken: true,
  },
};
