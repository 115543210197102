export const LOCALES = ['de-de', 'de-ch', 'fr-ch', 'it-ch', 'fr-fr', 'pl-pl', 'en-gb'];
type LOCALE_TYPES = typeof LOCALES[number]

const isValidLocale = (locale?: string) => {
  if (locale !== undefined && locale !== null) {
    return LOCALES.includes(locale.toLowerCase());
  }
  return false;
};

export const castLocale = (locale?: string): string => {
  if (locale === undefined || locale === null || !isValidLocale(locale)) return 'de-CH';

  const split = locale.split('-');
  return `${split[0].toLowerCase()}-${split[1].toUpperCase()}`;
};

export const LOCALE_MARKET_MAP: {[K in LOCALE_TYPES]: string} = {
  'de-de': 'Germany',
  'de-ch': 'Switzerland_german',
  'fr-ch': 'Switzerland_french',
  'it-ch': 'Switzerland_italian',
  'fr-fr': 'France',
  'pl-pl': 'Poland',
  'en-gb': 'Switzerland_german',
};
