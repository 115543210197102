import { RouteName } from '@frontend/routes';
import useApplication from '@frontend/context/Application';

const useRouteBuilder = () => {
  const { locale, useLocaleRoutes } = useApplication();

  const buildRoute = (target: RouteName) => {
    if (useLocaleRoutes) {
      return `/${locale}/${target}`;
    }
    return `/${target}`;
  };

  return {
    buildRoute,
  };
};

export default useRouteBuilder;
