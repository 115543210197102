const UtilService = () => {
  // TODO: find better solution if this makes problems
  // this method is yet kinda flawed with fractions ending on 5, ex. 35.855 => 35.85 instead of 35.86
  const mathToFixed = (input: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return (Math.round(input * factor) / factor);
  };

  const getErrorMessage = (error: Error|string) => {
    if (error instanceof Error && error.message) {
      return error.message;
    } else return error;
  };

  const getUrlQueryString = (paramName: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  };

  return {
    mathToFixed,
    getErrorMessage,
    getUrlQueryString
  };
};

const utilService = UtilService();
export default utilService;
