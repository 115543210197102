import React, { MutableRefObject } from 'react';
import { Link } from 'react-router-dom';
import useApplication from '@frontend/context/Application';
import useWall from '@frontend/context/Wall';
import geberitLogo from '@frontend/images/geberit_logo.svg';

/* This is needed, so TypeScript knows about the ProAppJSInterface */
declare const ProAppJSInterface: any;
declare global {
  interface Window { ProAppJSInterface: any; }
}

interface HeaderProps {
  reference?: MutableRefObject<HTMLDivElement | null>;
}

const Header = ({ reference }: HeaderProps) => {
  const { locale, useLocaleRoutes, isMobileEnvironment, getTranslation } = useApplication();
  const { resetWall, showModal } = useWall();

  const onBack = () => {
    if ((typeof ProAppJSInterface !== 'undefined') || ('ProAppJSInterface' in window)) {
      if (ProAppJSInterface) {
        ProAppJSInterface.closeWebView();
      } else {
        window.ProAppJSInterface.closeWebView();
      }
    }
  };

  return (
    <header ref={reference} className={`header-outer-container ${isMobileEnvironment ? 'mobile-environment' : ''}`}>
      <div className="header-inner-container ">
        {isMobileEnvironment
          ? (
            <div className="mobile-header">
              {!showModal ? <i className="web20-icon-arrow-link-light-left" onClick={onBack} /> : <span className="mobile-header-spacer"></span>}
              <span className="mobile-header-title">{getTranslation('SilentPanelApplicationTitle')}</span>
              <span className="mobile-header-spacer"></span>
            </div>
          )
          : (
            <Link to={`/${useLocaleRoutes ? locale : ''}`} onClick={() => resetWall()} className="c-logo header-geberit-home-link">
              <img src={geberitLogo} title="GEBERIT" alt="GEBERIT" className="header-logo" />
              <span className="header-country">{locale?.split('-')[1]?.toUpperCase()}</span>
            </Link>
          )}
      </div>
    </header>
  );
};

export default Header;
