import { DuofixWallHeight } from './DuofixWallHeight';

// TODO: use all and correct market-ids in the correct options
export const DUOFIX_HEIGHTS_LIST: DuofixWallHeight[] = [
  {
    value: 83.8,
    title: 'LabelDuofixWallHeight_82',
    markets: ['Germany', 'Switzerland_german', 'Switzerland_italian', 'Switzerland_french', 'France'],
  },
  {
    value: 99.8,
    title: 'LabelDuofixWallHeight_98',
    markets: ['Germany', 'Switzerland_german', 'Switzerland_italian', 'Switzerland_french', 'France'],
  },
  {
    value: 113.8,
    title: 'LabelDuofixWallHeight_112',
    markets: ['Germany', 'Switzerland_german', 'Switzerland_italian', 'Switzerland_french', 'France'],
  },
  {
    value: 123.8,
    title: 'LabelDuofixWallHeight_122',
    markets: ['Germany', 'Switzerland_german', 'Switzerland_italian', 'Switzerland_french', 'France'],
  },
  {
    value: 131.8,
    title: 'LabelDuofixWallHeight_130',
    markets: ['Germany', 'Switzerland_german', 'Switzerland_italian', 'Switzerland_french', 'France'],
  },
  {
    value: 145.8,
    title: 'LabelDuofixWallHeight_144',
    markets: ['Switzerland_german', 'Switzerland_italian', 'Switzerland_french'],
  },
];
