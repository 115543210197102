import React from 'react';
import useApplication from '@frontend/context/Application';
import Headset from '@frontend/images/headset.svg';

function Footer() {
  const { getTranslation, isMobileEnvironment } = useApplication();
  const copyrightText = `© ${new Date().getFullYear()} ${getTranslation('LabelSPCompanyName')}`;

  const handleCookieSettings = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.UC_UI) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.UC_UI.showSecondLayer();
    }
  };

  return (
    <footer className={`footer-container ${isMobileEnvironment ? 'mobile-environment' : ''}`}>
      <div className="footer-inner-container">
        <div>
          <div className="footer-contact-wrapper">
            <div className="footer-contact-title">{getTranslation('LabelSPFooterContact')}</div>
            <div className="footer-contact-link">
              <div className="icon-container">
                <img src={Headset} alt="headset" />
              </div>
              <span className="footer-contact-link-text">{getTranslation('LinkSPTechnicalHelp')}</span>
            </div>
            {/* <div className="icon-container">
              <img src={Headset} alt="headset" />
              <span className="footer-contact-link-text">{getTranslation('LinkSPTechnicalHelp')}</span>
            </div> */}
            {/* <a className="footer-contact-link" href={getTranslation('LinkSPTechnicalHelp')}>
              <div className="icon-container">
                <img src={Headset} alt="headset" />
              </div>
              <span className="footer-contact-link-text">{getTranslation('LabelSPTechnicalHelp')}</span>
            </a> */}
          </div>
          {!isMobileEnvironment && (
            <div className="footer-content c-footer__nav">
              <p className="footer-copyright">{copyrightText}</p>
              <a className="footer-link" target="_blank" href={getTranslation('LinkSPFooterlinkImpressum')}>{getTranslation('LabelSPFooterlinkImpressum')}</a>
              <a className="footer-link" target="_blank" href={getTranslation('LinkSPFooterlinkDisclaimer')}>{getTranslation('LabelSPFooterlinkDisclaimer')}</a>
              <a className="footer-link" target="_blank" href={getTranslation('LinkSPFooterlinkDataPrivacy')}>{getTranslation('LabelSPFooterlinkDataPrivacy')}</a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
              <a className="footer-cookie-consent" onClick={() => handleCookieSettings()} data-translate="_footer_cookie-consent">{getTranslation('LabelSPFooterlinkCookieConsent')}</a>
              <a className="footer-link" target="_blank" href={getTranslation('LinkSPFooterlinkDeclarations')}>{getTranslation('LabelSPFooterlinkDeclarations')}</a>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
