import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { UrlParamType } from '@core/src/types/GlobalTypes';
import LoadingOverlay from '@frontend/components/layout/LoadingOverlay';
import UtilService from '@core/src/services/utils.service';
import TranslationService from '@core/src/services/translation.service';
import { getLocaleConfigByDomain } from '@core/src/services/domain.service';
// import DataService from '@core/src/services/data.service';

export interface ApplicationProviderService {
  getTranslation: (key: string) => string,
  locale: string,
  useLocaleRoutes: boolean,
  isMobileEnvironment: boolean
}

export interface ApplicationProviderProps {
  children?: React.ReactNode;
}

const ApplicationContext = createContext<ApplicationProviderService>({} as ApplicationProviderService);

const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const { urlLocale } = useParams<UrlParamType>();
  const [useLocaleRoutes, setUseLocaleRoutes] = useState<boolean>(false);
  const [isMobileEnvironment, setIsMobileEnvironment] = useState<boolean>(false);
  const locale = urlLocale ?? getLocaleConfigByDomain(window.location.hostname) ?? 'de-CH';

  const getTranslation = (key: string): string => TranslationService.getTranslation(key, locale);

  useEffect(() => {
    if (urlLocale) {
      setUseLocaleRoutes(true);
    }
    if (UtilService.getUrlQueryString('platform') === 'mobile') {
      setIsMobileEnvironment(true);
    }
  }, []);

  useEffect(() => {
    if (urlLocale) {
      setUseLocaleRoutes(true);
    }
  }, [urlLocale]);

  return (
    <ApplicationContext.Provider
      value={{
        getTranslation,
        locale,
        useLocaleRoutes,
        isMobileEnvironment,
      }}
    >
      <LoadingOverlay area="apartmentData" />
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplication = () => useContext(ApplicationContext);

export default useApplication;
export { ApplicationProvider, ApplicationContext };
