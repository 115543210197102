/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';

import {
  BrowserRouter, Route, Switch, Redirect, useLocation, useParams,
} from 'react-router-dom';

// style imports
import './styles/bootstrap_loader.scss';
import './styles/global.scss';

import LoadingOverlay from '@frontend/components/layout/LoadingOverlay';
import { ReactNotifications } from 'react-notifications-component';
import useApplication, { ApplicationProvider } from '@frontend/context/Application';
import { urlLocaleRegex } from '@core/src/services/domain.service';
import Footer from './layout/Footer';
import Header from './layout/Header';
// import authenticationService from './services/authentication.service';
import { LoadingOverlayProvider } from './context/LoadingOverlay';
import { WallProvider } from './context/Wall';
import WallSelectorModal from './layout/WallSelectorModal';

// config Google Tag Manager
if (process.env.REACT_APP_GTMID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTMID });
}

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const BaseLayout = React.lazy(() => import('./layout/BaseLayout'));
const Page404 = React.lazy(() => import('./views/Error404'));
const Page500 = React.lazy(() => import('./views/Error500'));
// const Home = React.lazy(() => import('./views/Home'));

interface BaseComponentWrapperProps {
  children?: React.ReactNode
}

function BaseComponentWrapper({ children }: BaseComponentWrapperProps) {
  const location = useLocation();
  const { isMobileEnvironment } = useApplication();
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname]);

  return (
    <WallProvider>
      <ReactNotifications />
      <Header reference={containerRef} />
      <LoadingOverlay />
      <div className={`main-container ${isMobileEnvironment ? 'mobile-environment' : ''}`}>
        <div className="content-container">
          {children}
        </div>
      </div>
      <Footer />
      <WallSelectorModal />
    </WallProvider>
  );
}

function HybridRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
    >
      <LoadingOverlayProvider>
        <ApplicationProvider>
          <BaseComponentWrapper>
            <Component />
          </BaseComponentWrapper>
        </ApplicationProvider>
      </LoadingOverlayProvider>
    </Route>
  );
}

export function NotFoundRoute({ ...rest }: any) {
  const { urlLocale } = useParams<{urlLocale: string}>();

  if (urlLocale !== undefined) {
    return <Route {...rest}><Redirect to={`/${urlLocale}/404`} /></Route>;
  }

  return <Route {...rest}><Redirect to="/404" /></Route>;
}

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <HybridRoute exact path={`/:urlLocale${urlLocaleRegex}?/404`} name="Page 404" component={Page404} />
          <HybridRoute exact path={`/:urlLocale${urlLocaleRegex}?/500`} name="Page 500" component={Page500} />
          <HybridRoute path={`/:urlLocale${urlLocaleRegex}?/`} name="Content" component={BaseLayout} />
          <NotFoundRoute from="*" to={`/:urlLocale${urlLocaleRegex}?/404`} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
