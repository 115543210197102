/**
 * Hook for accessing the GA datalayer
 * and logs for debugging purposes when on local environment
 */
const useTracking = () => ({
  trackEvent: (category: string, action: string, label?: string) => {
    const trackingData = {
      event: 'genericEvent',
      eventProps: {
        category,
        action,
        label,
      },
    };

    if ((window && window.location.hostname === 'localhost')) {
      // eslint-disable-next-line no-console
      console.log('TRACKING EVENT', trackingData);
    }

    const win = window as any;
    if (win && win.dataLayer) {
      win.dataLayer.push(trackingData);
    }
  },
});

export default useTracking;
