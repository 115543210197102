import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { ProjectWallModel, SystemType, WallInfo } from '@core/src/classes/Project';
import { v4 as uuidv4 } from 'uuid';
import useApiRequestService from '@frontend/services/useApiRequestService';
import ApiRequestBuilder from '@frontend/requests/api-request.builder';
import { BACKEND_API_ENDPOINTS } from '@frontend/requests/api-endpoints-list';
import { LOCALE_MARKET_MAP } from '@frontend/types/LocalMarketMap';
import { SelectionObject } from '@core/src/types/SelectionObject';
import { DUOFIX_HEIGHTS_LIST } from '@frontend/types/duofix-heights-list';
import useTracking from '@frontend/services/useTracking';
import { trackPromise } from 'react-promise-tracker';
import useOverlay from '@frontend/context/LoadingOverlay';
import useApplication from './Application';

export interface WallProviderService {
  setInstallationSystem: (system: SystemType) => void;
  setWall: (Dispatch<SetStateAction<ProjectWallModel>>),
  wall: ProjectWallModel,
  market: string,
  updateInstallationWall: (wallElement: ProjectWallModel) => void;
  resetWall: () => void,
  setInstallationWall: (selectedInstallationWall: SelectionObject) => void,
  showModal: boolean,
  setShowModal: (Dispatch<SetStateAction<boolean>>),
  availableInstallationSystems: string[];
}

export interface WallProviderProps {
  children?: React.ReactNode;
}

// TODO This is a copy from EditorContext.tsx, relocate into core as soon as everything is ok
const generateDefaultWallInfo = (object: SelectionObject, wallSystem: string, market: string): WallInfo => {
  const partials = ['wooxo', 'wooxx', 'woxoo', 'woxox', 'wxoxo', 'wxoxx', 'wxxoo', 'wxxox'];
  const code = object.objectType;
  let wallWidth = 400;
  const defaultWallHeight = 240;
  const panelScaleFactor = 0.5;
  const defaultFloorLevel = 10;
  const gisDefaultHeight = 115;
  const duofixDefaultHeight = DUOFIX_HEIGHTS_LIST.filter(
    (obj) => (obj.markets.includes(market) && obj.value === 113.8),
  )
    ? 113.8
    : DUOFIX_HEIGHTS_LIST.filter((obj) => (obj.markets.includes(market)))[0].value;

  const defaultHeight = wallSystem.toLowerCase() === 'duofix' ? duofixDefaultHeight : gisDefaultHeight;

  // Special rule for room divider partial height full width
  if (code === 'wxooxs') {
    wallWidth *= panelScaleFactor;
  }

  // const isRoomDivider
  const isFullWidth = code[1] === 'x' && code[4] === 'x';
  const isFullHeight = code[2] === 'x' || code[3] === 'x';
  const hasPartial = partials.includes(code);

  const getOffset = () => {
    if (isFullWidth) {
      return 0;
    }
    if (code[1] === 'x' && code[4] === 'o') {
      return 0;
    }
    if (code[1] === 'o' && code[4] === 'x') {
      return wallWidth - (wallWidth * panelScaleFactor);
    }
    return (wallWidth / 2) - ((wallWidth * panelScaleFactor) / 2);
  };

  const getPartialSize = () => {
    const width = isFullWidth ? wallWidth * panelScaleFactor : wallWidth * panelScaleFactor * panelScaleFactor;

    return {
      width: hasPartial ? width : 0,
      height: hasPartial ? defaultHeight : 0,
    };
  };

  return {
    dimensionsWall: {
      width: wallWidth,
      height: defaultWallHeight,
    },
    dimensionsPanel: {
      width: isFullWidth ? wallWidth : wallWidth * panelScaleFactor,
      height: isFullHeight ? defaultWallHeight - defaultFloorLevel : defaultHeight,
      offset: getOffset(),
      wallDepth: 25,
      floorLevel: defaultFloorLevel,
    },
    dimensionsPartialPanel: getPartialSize(),
    selectedInfo: object,
  };
};

const WallContext = createContext<WallProviderService>({} as WallProviderService);

const WallProvider = ({ children }: WallProviderProps) => {
  const { callApi } = useApiRequestService();
  const { locale, getTranslation } = useApplication();
  const { trackEvent } = useTracking();
  const [availableInstallationSystems, setavailableInstallationSystems] = useState([]);
  const [market, setMarket] = useState('');
  const { setOverlayMessage } = useOverlay();

  const emptyWall: ProjectWallModel = {
    id: uuidv4(),
    title: '',
    wallType: '',
    wallSystem: '',
    wallInfo: undefined,
    wallObjects: [],
  };

  const [wall, setWall] = useState<ProjectWallModel>(emptyWall);
  const [showModal, setShowModal] = useState<boolean>(false);

  const resetWall = () => {
    setWall(emptyWall);
  };

  const setInstallationSystem = (system: SystemType) => {
    const modifiedWall = { ...wall };
    modifiedWall.wallSystem = system;
    setWall(modifiedWall);
  };

  useEffect(() => {
    const apiConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.getMarketValues, { marketId: LOCALE_MARKET_MAP[locale.toLowerCase()] })
      .build();
    setOverlayMessage(getTranslation('LabelIsLoading'));
    trackPromise(callApi(apiConfig)
      .then((data) => {
        setMarket(data.MarketId);
        setavailableInstallationSystems(data.InstallationSystems);
      })
      .catch(() => {
        throw new Error('Error deleting project');
      }));
  }, []);

  const updateInstallationWall = (wallElement: ProjectWallModel) => {
    if (!wallElement) { return; }

    const objectsToMove: SelectionObject[] = [];

    wallElement.wallObjects.forEach((object) => {
      const objectWidth = (object?.dimensions?.width || 0);
      const panelWidth = (wallElement?.wallInfo?.dimensionsPanel.width || 0);

      if ((object?.offsetX || 0) + objectWidth > panelWidth) {
        objectsToMove.push({
          ...object,
          offsetX: (panelWidth - (objectWidth / 2)),
        });
      }
    });
    const updatedWallObjects = wallElement.wallObjects.map((item) => {
      const replacement = objectsToMove.find((obj) => obj.uid === item.uid);
      if (replacement) {
        return replacement;
      }
      return item;
    });
    wallElement.wallObjects = updatedWallObjects;
    wallElement.highlightErrors = false;

    setWall(wallElement);
  };

  const setInstallationWall = (selectedInstallationWall: SelectionObject) => {
    if (!wall) { return; }

    const modifiedWall: ProjectWallModel = { ...wall };

    // TODO exchange 'Switzerland_german' with a dynamic id
    modifiedWall.wallInfo = generateDefaultWallInfo(selectedInstallationWall, wall.wallSystem, 'Switzerland_german');

    trackEvent('prewall-added', selectedInstallationWall.category, selectedInstallationWall.objectType);

    setWall(modifiedWall);
  };

  return (
    <WallContext.Provider
      value={{
        setInstallationSystem,
        setWall,
        wall,
        updateInstallationWall,
        market,
        resetWall,
        setInstallationWall,
        showModal,
        setShowModal,
        availableInstallationSystems,
      }}
    >
      {children}
    </WallContext.Provider>
  );
};

const useWall = () => useContext(WallContext);

export default useWall;
export { WallProvider, WallContext };
