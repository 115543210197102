import React, { CSSProperties } from 'react';

export interface IconProps {
    icon: string;
    wrapper?: boolean;
    inline?: boolean;
    background?: boolean;
    wrapperClasses?: string;
    border?: boolean;
    active?: boolean;
    hover?: boolean;
    onClick?: ()=>void;
    style?: CSSProperties;
}

/**
 * Usage:
 * <Icon icon="plus" background wrapper={true} inline={true} />
 */
const Icon: React.FC<IconProps> = (props) => {
  let additionalClasses = '';
  additionalClasses += (props.wrapperClasses) ? ` ${props.wrapperClasses}` : '';
  additionalClasses += (props.background) ? ' icon-background' : '';
  additionalClasses += (props.inline) ? ' inline' : '';
  additionalClasses += (props.border) ? ' border' : '';
  additionalClasses += (props.active) ? ' active' : '';
  additionalClasses += (props.hover) ? ' hover' : '';
  additionalClasses += (props.onClick !== undefined) ? ' clickable' : '';

  return (
    <>
      {(props.wrapper)
        ? (
          <div style={props.style} onClick={props.onClick} className={`icon-wrapper ${additionalClasses}`}>
            <i className={`web20-icon-${props.icon}`} />
          </div>
        )
        : <i style={props.style} onClick={props.onClick} className={`web20-icon-${props.icon}`} /> }
    </>
  );
};

export default Icon;
