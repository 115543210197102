import React, { useEffect, useState } from 'react';
import Icon from '@frontend/components/basic/Icon';
import useApplication from '@frontend/context/Application';
import useWall from '@frontend/context/Wall';
import useRouteBuilder from '@frontend/services/useRouteBuilder';
import { useHistory } from 'react-router-dom';
import { SelectionObject } from '@core/src/types/SelectionObject';
import { SELECTION_OBJECTS_WALLS } from '@frontend/types/selection-objects-list';
import { allWallImages } from '../../images/DynamicImages';

interface WallListProps {
  wallTypeCategory: string;
  wallSystem: string;
  onChangeWall: () => void;
}

const WallList = ({ wallTypeCategory, wallSystem, onChangeWall } : WallListProps) => {
  const { getTranslation } = useApplication();
  const { setInstallationWall } = useWall();
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [selectionObjects, setSelectionObjects] = useState<SelectionObject[]>();
  const { buildRoute } = useRouteBuilder();
  const { push } = useHistory();

  const changeWall = (selectionObject: SelectionObject) => {
    setInstallationWall(selectionObject);
    push(buildRoute('dimension'));
    onChangeWall();
  };

  useEffect(() => {
    setTitle(getTranslation(wallTypeCategory === 'normalWall' ? 'LabelPreWall' : 'LabelRoomDivider'));
    setText(getTranslation(`LabelTopicListAdditionalText_${wallTypeCategory}`));

    setSelectionObjects(SELECTION_OBJECTS_WALLS.filter((selectionObject) => (
      selectionObject.category === wallTypeCategory
      && (
        selectionObject.wallSituation?.systemRequired?.toLowerCase() === wallSystem.toLowerCase()
        || !selectionObject.wallSituation?.systemRequired
      )
    )));
  }, [wallTypeCategory, wallSystem]);

  const getWallImage = (selectionObject: SelectionObject) => allWallImages[selectionObject.objectType];

  return (
    <div className="wallist-container">
      <h2 className="wallist-title">
        {title}
      </h2>
      <p className="wallist-text">
        {text}
      </p>
      <div className="wallist-list">
        {selectionObjects?.map((selectionObject) => (
          <div key={selectionObject.objectType} role="button" tabIndex={0} className="wallist-listentry" onClick={() => changeWall(selectionObject)}>
            <div className="wallist-listentry-chevron">
              <Icon icon="chevron-large-left" />
            </div>
            <div className="wallist-listentry-image">
              <img src={getWallImage(selectionObject)} alt={selectionObject.objectType} />
            </div>
            <div className="wallist-listentry-text">
              <p>{getTranslation(`LabelWalltype_${selectionObject.objectType}`)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WallList;
