import allTranslations from '@core/data/generated/Translations.json';
import allArticleTranslations from '@core/data/generated/ProductTranslations.json';
import { NameValueMap } from '@core/src/types/KeyValueMap';

const TranslationService = () => {

  const getAllTranslations = () => {
    return allTranslations.concat(allArticleTranslations);
  };

  const translationStore = getAllTranslations(); //for api side

  const getTranslation = (key: string, locale = 'de-CH'): string => {
    if (!translationStore) return `${key}**`;
    const localeParts = locale.split('-');
    const loc = `${localeParts[0]}-${localeParts[1].toUpperCase()}`;
    const translation: NameValueMap<string|number>|undefined = translationStore.find((row) => row.key === key);
    return translation && translation[loc] !== undefined ? translation[loc].toString() : `${key}*`;
  };

  return {
    getAllTranslations,
    getTranslation,
  };

};

const translationService = TranslationService();
export default translationService;
