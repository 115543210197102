import useApplication from '@frontend/context/Application';
import useWall from '@frontend/context/Wall';
import React from 'react';
import WallList from '../components/walls/WallList';
import Footer from './Footer';
import Header from './Header';

function WallSelectorModal() {
  const { wall, showModal, setShowModal } = useWall();
  const { getTranslation, isMobileEnvironment } = useApplication();

  return (
    <div className={`wall-selector-modal ${showModal ? 'visible' : 'hidden'} ${isMobileEnvironment ? 'mobile-environment' : ''}`}>
      <Header />
      <div className="back-button-container" role="button" tabIndex={-1} onClick={() => setShowModal(false)}>
        <i className="web20-icon-arrow-link-light-left" />
        <span>{getTranslation('LabelSPModalBackLink')}</span>
      </div>
      <WallList wallSystem={wall.wallSystem} wallTypeCategory={wall.wallType} onChangeWall={() => setShowModal(false)} />
      <Footer />
    </div>
  );
}

export default WallSelectorModal;
